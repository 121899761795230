import React from 'react';
import './Advantage.css';
import {useHistory} from 'react-router-dom';
import Fade from 'react-reveal/Fade';

function Advantage() {

  const history = useHistory();
  function onButtonClick() {
    history.push('/');
  }

  return (
    <div className="container1">
      <div className="advantageContainer" id="advantage">
        <Fade top duration="200" delay={250}>
          <p className="advantageFirstTitle">ADVANTAGE</p>
        
          <p className="advantageSecondTitle">About Collabgistics</p>
          <p className="advantageContent">
            COLLABGISTICS, INC. solution solves existing hurdles for mid 
            to large 
            size companies within the logistics industry. Our middleware 
            solution is revolutionary and one-of-a-kind providing 
            immutable virtual documentation sharing software that 
            interoperates with each of your stakeholders seamlessly and 
            securely. Resulting in the increase of your logistics companies’ 
            efficiency in every department and in turn your profit margins. 
            With volatile markets, geopolitical influence and most recent 
            the Covid-19 Pandemic; logistics companies need to rapidly move 
            to adopt new technologies. Logistics industry majors across the 
            value chain have the opportunity to use COLLABGISTICS solutions 
            to prioritize operational efficiencies, in their technology 
            adoption.
          </p>
        </Fade>
        <div className="advantageNavigationButtonsContainer">
            <button className="advantageNavigationButton" onClick={onButtonClick}>PREV</button>
            <p className="advantageNavigationButtonsDelimiter">|</p>
            <button className="advantageNavigationButton" onClick={onButtonClick}>NEXT</button>
          </div>
      </div>
    </div>
  );

}

export default Advantage;