import React from 'react';
import './SocialIcons.css';
import {ReactComponent as FacebookIcon} from './../../img/Icon-Dark-facebook.svg';
import {ReactComponent as TwitterIcon} from './../../img/Icon-LightGrey-Twitter.svg';

function SocialIcons() {
  return (
    <div className="socialContainer">
      <FacebookIcon className="facebookIcon" />
      <TwitterIcon  className="twitterIcon" />
    </div>
    
  );
}

export default SocialIcons;