import './Contact.css';
import envelopeImage from './../../img/envelope.svg';
import houseImage from './../../img/house.svg';
import phoneImage from './../../img/phone.svg';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

function Contact() {

  return(
    <div className="contactContainer" id="contact">
      <Fade top duration="300">
      <div className="contactHeadings">
        <p className="contactTitle">CONTACT</p>
        <p className="contactSecondaryTitle">International logistics collaboration 
        for ledger technology based interoperability.</p>
      </div>
      </Fade>
      <div className="infoContainer">
        <Zoom top cascade duration="500">
          <div className="contactElement">
            <img src={envelopeImage} />
            <p className="contactElementTitle">Email</p>
            <p className="contactElementContent">info@collabgistics.com</p>
          </div>
          <div className="contactElement">
            <img src={houseImage} />
            <p className="contactElementTitle">Address</p>
            <p className="contactElementContent">4 Heatherington Lane Savannah, GA 31411 United States of America</p>
          </div>
          <div className="contactElement">
            <img src={phoneImage} />
            <p className="contactElementTitle">Phone</p>
            <p className="contactElementContent">(877) 311-9554</p>
          </div>
        </Zoom>
      </div>
    </div>
  );
}

export default Contact;