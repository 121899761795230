import React from 'react';
import './News.css';
import Fade from 'react-reveal/Fade';


function News() {
  return (
    <div className="container5" id="news">
      <Fade left duration="300">
      <div className="container5Upper">
          <p className="newsTitle">News</p>
      </div>
        <div className="container5Lower">
          <div className="newsElementContainer">
            <p className="newsElementDate">18 JUNE 2021</p>
            <div className="newsElementText">
              <p className="newsElementTitle">Savannah’s tech scene continuing to grow with logistics, 
                firearms and music startups
              </p>
              <p className="newsElementContent">Collabgistics, Inc. has a robust scale-up plan for 
              growth working with talented individuals within the SLTC. We look forward to the 
              quality of life that living and working in the historical city of Savannah brings to 
              each of our employees and to the success of Collabgistics, Inc as a whole,” Stevens 
              said.
              </p>
              <a className="readMoreLink" href="https://eu.savannahnow.com/story/business/2020/12/24/start-ups-savannah-logistics-technology-corridor-jobs-firearms-music-money-transfer/3939308001/" target="_blank">READ MORE</a>
            </div>
          </div>
          
          <div className="newsElementContainer">
            <p className="newsElementDate">12 JULY 2021</p>
            <div className="newsElementText">
              <p className="newsElementTitle">Bill Stankiewicz joins Collabgistics, Inc. Advisory Board
              </p>
              <p className="newsElementContent">(SAVANNAH, GA) Collabgistics, Inc., a software firm 
              dedicated to bringing the efficiency and security of hyperledger technology to the 
              logistics industry, has recruited veteran Savannah logistics expert Bill Stankiewicz 
              to its advisory board. Stankiewicz is the CEO of Savannah Supply Chain and an adjunct 
              professor at Savannah Technical College. He brings more than two decades of experience 
              in industrial facility operations and distribution to the role.
              “I am thrilled to join the Collabgistics Advisory Board supporting the company’s mission to bring new t
              echnologies to the logistics industry,” Stankiewicz said. “Blockchain in logistics will pave the way 
              for more efficiencies in several areas. Also, with the cost savings, it is imperative for logistics 
              companies.”
              </p>
              <a className="readMoreLink" href="https://mysavannah.wordpress.com/2021/07/12/bill-stankiewicz-joins-collabgistics-inc-advisory-board/" target="_blank">READ MORE</a>
            </div>
          </div>
        </div>
        </Fade>
      </div>
  );
}

export default News;