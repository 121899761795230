import React from 'react';
import './Solution.css';
import mobileImage from './../../img/Group 2417.png';
import Fade from 'react-reveal/Fade';

function Solution() {
  return (
    <div className="container2" id="solution">
        <div className="container2Left">
        <Fade top cascade duration="200">
          <p className="theSolutionFirstTitle">THE SOLUTION</p>
          <p className="theSolutionSecondTitle">Collabgistics end-to-end</p>
          <p className="theSolutionContent">
          Collabgistics solution enables companies to collaborate securely and effectively 
          across the supply chain delivering immense business advantages. Our software 
          solution for logistics businesses streamlines their data for management, 
          forecasting and planning control.
          </p>
        </Fade>
        </div>
        <div className="container2Right">
          <img alt="Smartphone app" src={mobileImage} className="mobileImage" />
        </div>
        
      </div>
  );
}

export default Solution;