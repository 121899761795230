import React, { useEffect, useState } from 'react';
import './Header.css';
import {ReactComponent as CollabgisticsLogo} from './../../img/Group 2424.svg';
import {Link} from 'react-scroll';
import {useHistory} from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

const burgerMenuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '15px',
    height: '15px',
    right: '5%',
    top: '2%',
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    width: '100%'
  },
  bmMenu: {
    background: '#000000f3',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.9em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '90%',
    
  },
  bmItem: {
    display: 'inline-block',
    margin: '0',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

function Header() {

  const [open, setOpen] = useState(false);
  const history = useHistory();
  function onButtonClick() {
    history.push('/');
    onHeaderButtonClick();
  }

  useEffect(() => {
  }, [open]);

  function onHeaderButtonClick() {

    const headerCloseButton = document.getElementById('react-burger-cross-btn');
    if (headerCloseButton) {
      headerCloseButton.click();
    }
  }

  return (
    <div className="header">
      <CollabgisticsLogo className="collabgisticsLogo" onClick={onButtonClick} />
      <div className="headerButtonsContainer">
        <button className="headerButton" onClick={onButtonClick}>HOME</button>
        <button className="headerButton"><Link activeClass="blueButton" spy={true} to="advantage" offset={-100} smooth={true}>ADVANTAGE</Link></button>
        <button className="headerButton"><Link activeClass="blueButton" spy={true} to="solution" offset={-100} smooth={true}>SOLUTION</Link></button>
        <button className="headerButton"><Link activeClass="blueButton" spy={true} to="team"  offset={-100} smooth={true}>TEAM</Link></button>
        <button className="headerButton"><Link activeClass="blueButton" spy={true} to="" offset={-100} smooth={true}>INVESTOR RELATIONS</Link></button>
        <button className="headerButton"><Link activeClass="blueButton" spy={true} to="partners" offset={-100} smooth={true}>PARTNERS</Link></button>
        <button className="headerButton"><Link activeClass="blueButton" spy={true} to="news" offset={-100} smooth={true}>PRESS</Link></button>
        <button className="headerButton"><Link activeClass="blueButton" spy={true} to="contact" offset={-100} smooth={true}>CONTACT</Link></button>
      </div>

      <div className="headerButtonsContainerMobile">
        <Menu styles={burgerMenuStyles} right isOpen={open}>
          <button className="headerButtonMobile" onClick={onButtonClick}>HOME</button>
          <button className="headerButtonMobile"><Link onClick={onHeaderButtonClick} activeClass="blueButton" spy={true} to="advantage" offset={-80} smooth={true}>ADVANTAGE</Link></button>
          <button className="headerButtonMobile"><Link onClick={onHeaderButtonClick} activeClass="blueButton" spy={true} to="solution" smooth={true}>SOLUTION</Link></button>
          <button className="headerButtonMobile"><Link onClick={onHeaderButtonClick} activeClass="blueButton" spy={true} to="team" offset={-80} smooth={true}>TEAM</Link></button>
          <button className="headerButtonMobile"><Link onClick={onHeaderButtonClick} activeClass="blueButton" spy={true} to="" smooth={true}>INVESTOR RELATIONS</Link></button>
          <button className="headerButtonMobile"><Link onClick={onHeaderButtonClick} activeClass="blueButton" spy={true} to="partners" smooth={true}>PARTNERS</Link></button>
          <button className="headerButtonMobile"><Link onClick={onHeaderButtonClick} activeClass="blueButton" spy={true} offset={-80} to="news" smooth={true}>PRESS</Link></button>
          <button className="headerButtonMobile"><Link onClick={onHeaderButtonClick} activeClass="blueButton" spy={true} to="contact" smooth={true}>CONTACT</Link></button>
        </Menu>
      </div>
      
    </div>
  );
}

export default Header;