import React, { useEffect } from 'react';
import './About.css';
import Solution from './Solution';
import Team from './Team';
import Partners from './Partners';
import News from './News';
import Advantage from './Advantage';
import Header from './Header';
import Contact from './Contact';

function About() {

  useEffect(()=> {
    const location = window.location.href;
    const scrollLocation = location.slice(location.lastIndexOf('#') + 1);
    const scrollElement: HTMLElement | null = document.getElementById(scrollLocation);
    if (scrollElement !== null) {
      scrollElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  })

  return (
    <div className="topLevelContainer">
      <Header />
      <Advantage />
      <Solution />
      <Team />
      <Partners />
      <News />
      <Contact />
    </div>
  );
}

export default About;