import React from 'react';
import './App.css';
import Home from './components/templates/Home';
import About from './components/templates/About';
import NotFound from './components/templates/About';
import {HashRouter, Route, Redirect} from 'react-router-dom';
import Switch from 'react-router-transition-switch';
import Fader from 'react-fader';

function App() {

  return (
    <HashRouter>
      <Switch component={Fader}>
          <Route key="home" exact path="/"><Home /></Route>
          <Route key="about" exact path="/about"><About /></Route>
          <Route path="/404"><NotFound /></Route>
          <Redirect to="/" />
      </Switch>
    </HashRouter>
  );
    
}

export default App;
