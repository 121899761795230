import React, { useEffect } from 'react';
import './Home.css';
import collabgisticsLogo from './../../img/Group 2424 (1).svg';
import {ReactComponent as MenuIcon} from './../../img/dots-menu.svg';
import menuIcon from './../../img/dots-menu.svg';
import arrowDownIcon from './../../img/down.svg';
import SocialIcons from './SocialIcons';
import {useHistory} from 'react-router-dom';
import {Link} from 'react-scroll';
import { slide as Menu } from 'react-burger-menu';
import Fade from 'react-reveal/Fade';


const burgerMenuStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '15px',
    height: '15px',
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    width: '100%'
  },
  bmMenu: {
    background: '#000000f3',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.9em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '90%',
    
  },
  bmItem: {
    display: 'inline-block',
    margin: '0',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

function Home() {
  const history = useHistory();

  function onButtonClick(section?: string) {
    if (section === undefined) {
      section = '';
    }
    history.push('/about' + "#" + section);
  }
  function onHeaderButtonClick(section: string) {

    const headerCloseButton = document.getElementById('react-burger-cross-btn');
    if (headerCloseButton) {
      headerCloseButton.click();
    }
    onButtonClick(section);
  }
  useEffect(() => {
    const onScroll: EventListener = (event: Event) => { // <-- DOM-EventListener
      onButtonClick();
  };

    const win: Window = window;   // <-- DOM-Window, extends DOM-EventTarget
    win.addEventListener("wheel", onScroll);

    return () => window.removeEventListener("wheel", onScroll);
  });

  useEffect(() => {
    document.body.className = 'signin';
    return () => { document.body.className = ''; }
  });

  
  return (
    <div className="container">
<Fade>
      <div className="contentWrapper">

        <div className="left">
          <p className="contactEmail">info@collabgistics.com</p>
          <div className="menuContainer">
            <p className="menuTextHomepage">MENU</p>
            {/* <img src={menuIcon} className="menuIcon" /> */}
            <Menu styles={burgerMenuStyles} right isOpen={false} customBurgerIcon= {<img src={menuIcon} className="menuIcon" />} >
              <button className="headerButtonMobile">HOME</button>
              <button className="headerButtonMobile"><Link onClick={() => onHeaderButtonClick('advantage')} activeClass="blueButton" spy={true} to="advantage" offset={-80} smooth={true}>ADVANTAGE</Link></button>
              <button className="headerButtonMobile"><Link onClick={() => onHeaderButtonClick('solution')} activeClass="blueButton" spy={true} to="solution" smooth={true}>SOLUTION</Link></button>
              <button className="headerButtonMobile"><Link onClick={() => onHeaderButtonClick('team')} activeClass="blueButton" spy={true} to="team" offset={-80} smooth={true}>TEAM</Link></button>
              <button className="headerButtonMobile"><Link onClick={() => onHeaderButtonClick('')} activeClass="blueButton" spy={true} to="" smooth={true}>INVESTOR RELATIONS</Link></button>
              <button className="headerButtonMobile"><Link onClick={() => onHeaderButtonClick('partners')} activeClass="blueButton" spy={true} to="partners" smooth={true}>PARTNERS</Link></button>
              <button className="headerButtonMobile"><Link onClick={() => onHeaderButtonClick('news')} activeClass="blueButton" spy={true} offset={-80} to="news" smooth={true}>PRESS</Link></button>
              <button className="headerButtonMobile"><Link onClick={() => onHeaderButtonClick('contact')} activeClass="blueButton" spy={true} to="contact" smooth={true}>CONTACT</Link></button>
            </Menu>
          </div>

        </div>

        <div className="middle">
          <div className="logoContainer">
            <img alt="Collabgistics logo" src={collabgisticsLogo} />
            {/* <object type="image/svg+xml" data="./../../img/Group 2424 (1).svg"></object> */}
            {/* <CollabgisticsLogo /> */}
          </div>
          <div className="titles">
            <div className="titleContainer">
              <h1 className="title">Efficient & Trusted Communication</h1>
            </div>
            
            <p className="secondaryTitle">Global integration and interoperability across the supply chain</p>
          </div>
          <div className="scrollIcon" onClick={() => onHeaderButtonClick('')}>
            <img alt="Scroll button" src={arrowDownIcon} className="arrowDownIcon" />
            <p className="scrollText">SCROLL</p>
          </div>
          
        </div>

        <div className="right">
          {/* <div className="menuContainer">
            <p className="menuTextHomepage">MENU</p>
            <MenuIcon className="menuIcon" />
          </div> */}


          <SocialIcons />

          

          <div className="navigationButtonsContainer">
            <button className="navigationButton" onClick={() => onHeaderButtonClick('')}>PREV</button>
            <p className="navigationButtonsDelimiter">|</p>
            <button className="navigationButton" onClick={() => onHeaderButtonClick('')}>NEXT</button>
          </div>
        </div>

      </div>
      </Fade>
    </div>
  );
}

export default Home;