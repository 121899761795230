import React from 'react';
import './Partners.css';
import slicLogo from '../../img/logo-3.png';
import outsourceLogo from '../../img/logo-1.png';
import samLogo from '../../img/logo-2.png';
import bitaLogo from '../../img/Image 28.png';
import starclusterLogo from '../../img/Image 31.png';
import carriageLogo from '../../img/Untitled-4.png';
import {ReactComponent as SavannahSupplyChainLogo} from '../../img/Savannah Supply Chain.svg';
import Fade from 'react-reveal/Fade';


function Partners() {
  return (
    <div className="container4" id="partners">
      <Fade>
        <div className="container4Upper">
          <p className="partnersTitle">Partners</p>
        </div>
        <div className="container4Lower">
          <a className="logoBox" href="https://outsourcelogistics.com/" target="_blank">
            <img alt="Outsource Logo" src={outsourceLogo} className="partnerLogo" />
          </a>
          <a className="logoBox" href="http://savannahsupplychain.com/" target="_blank">
            <SavannahSupplyChainLogo />
          </a>
          <a className="logoBox" href="https://stubbsalderton.com/" target="_blank">
            <img alt="SA&M Logo" src={samLogo} className="partnerLogo" />
          </a>
          <a className="logoBox" href="https://carriagetradepr.com/category/s1-main/" target="_blank">
            <img alt="Carriage Logo" src={carriageLogo} className="partnerLogo" />
          </a>
          <a className="logoBox" href="https://www.bita.studio/" target="_blank">
            <img alt="BITA Logo" src={bitaLogo} className="partnerLogo" />
          </a>
          <a className="logoBox" href="http://savannahlogisticscorridor.com/" target="_blank">
            <img alt="Slic Logo" src={slicLogo} className="partnerLogo" />
          </a>
          <a className="logoBox" href="https://starcluster.tech/" target="_blank">
            <img alt="Starcluster Logo" src={starclusterLogo} className="partnerLogo" />
          </a>
          <a className="logoBox">

          </a>
          
        </div>
        </Fade>
      </div>
  );
}

export default Partners;