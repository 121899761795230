import './MemberModal.css';

interface Props {
  members: any[];
  currentMember: number;
  setCurrentMember: Function;
}

export const MemberModal = ({
  members,
  currentMember,
  setCurrentMember,
}: Props) => {
  return (
    <section id='team_modal'>
      <div id='team_modal_content' className='slider'>
        <span id='close_modal' onClick={() => setCurrentMember(-1)}>
          x
        </span>

        <div className='teamModalWrapper'>
          {members.map((member, index) => (
            <img
              src={member.avatar}
              alt={`${member.name}' portrait`}
              className={`avatar ${index === currentMember ? 'active' : ''}`}
              onClick={() => setCurrentMember(index)}
            />
          ))}
        </div>

        <div className='slides'>
          <div>
            <h3>{members[currentMember].name}</h3>
            <h4>{members[currentMember].position}</h4>
            <p>{members[currentMember].about}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
