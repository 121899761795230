import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useState } from 'react';
import Fade from 'react-reveal/Fade';
import danielAvatar from '../../img/hertz.jpeg';
import billAvatar from '../../img/Image 22.png';
import keithAvatar from '../../img/Image 26.png';
import rachaelAvatar from '../../img/Image5.png';
import johnCouch from '../../img/JohnCouch.jpeg';
import lucaAvatar from '../../img/Luca.jpeg';
import dotsIcon from './../../img/dots-icon.svg';
import linkedInIcon from './../../img/linkedin (7).svg';
import { MemberModal } from './MemberModal';
import './Team.css';

const memberData = [
  {
    avatar: rachaelAvatar,
    name: 'Rachael E. Stevens',
    position: 'CEO/Founder',
    about: `Mrs. Rachael E. Stevens has 15+ years of experience starting in virtue private networks leading a successful career in several industries globally. She is a high performing executive with expertise in building/optimizing organizational processes, measurement systems to maximize business results and service operations worldwide. \n 
    Founding Collabgistics, INC. In 2018 with the mission to pioneer a more efficient future for the global logistics industry. Member of NAPW National Association of Professional Women New York, NY`,
  },
  {
    avatar: johnCouch,
    name: 'John Couch',
    position: 'Advisor',
    about: `Couch holds a Bachelor degree in computer science and a Masters degree in electrical engineering and computer science, both from the University of California at Berkeley where he was honored in 2000 as a Distinguished Alumnus. \n
    He has also been awarded an Honorary Doctorate of Humane Letters and a Leadership in Innovation Award from Philadelphia University. He is the co-author of the science research associates textbook, Compiler Construction: Theory and Practice. His new book, “Rewiring Education, How Technology Can Unlock every Student’s Potential” is available on Amazon which proposes a new pedagogy for K-12 education. His new book, My Life at Apple, and the Steve I knew” is also available on Amazon. He has taught courses at both University of California, Berkeley and at Cal State San Jose.Couch began his professional career in 1972 as a software engineer at Hewlett-Packard, and held various software management positions. \n
    In 1978 he became Apple’s 54th employee, as Director of New Products reporting to Steve Jobs.  He was Apple’s first Vice President of Software and Vice President/General Manager for the Lisa division, Apple’s first GUI Computer At Steve’s request John returned to Apple in 2002, as Apple’s First Vice President of Education responsible for Sales and Marketing growing Apple’s Education business to 9.5 billion over ten years. Today, John presents at numerous national and international conferences on learning.`,
  },
  {
    avatar: billAvatar,
    name: 'Bill Stankiewicz',
    position: 'Advisory Board Member',
    about: `Mr. Stankiewicz is a supply chain executive; Mr. Stankiewicz has 20+ years experience in industrial facility operations and food grade distribution. He is the CEO of Savannah Supply Chain and teaches as a adjunct professor at Savannah Technical College, where he is involved with the OS & OOG Freight Program for Pilot Escort and Fork Lift Safety Programs for certification and distribution operations. \n 
    He is the recipient of the Pepsi co-frito-lay service and quality awards at the 13th annual contracting manufacturing meeting in Plano, TX.`,
  },
  {
    avatar: danielAvatar,
    name: 'Daniel Bugarin',
    position: 'CTO/Chief Technical Officer',
    about: `Daniel has over a decade of experience, working in both software development and information security. He lead a world-famous security contest team, penthackon, finishing 5th in the world in 2014. He previously lead the technical department of an investment fund as well as the security efforts of eMAG, one of the largest e-commerce companies in Eastern Europe. \n 
    His sharp security skills, his keen interest in business development and his native analytical skill used for providing practical ideas in regards to any vision made him a valuable member of the team.`,
  },
  {
    avatar: keithAvatar,
    name: 'Keith Fletcher',
    position: 'Advisory Board Member',
    about: `Mr. Fletcher has 25+ years of dedication in the technological industries holding the position of CTO leading mid size firms to Fortune 500 companies. \n 
    He is a technically astute, accomplished executive with proven success redesigning, building, and reinventing it departments to ensure system reliability, operational productivity, project delivery times, and it service levels while reducing costs. Excels at strategic planning, building high-performance teams, project management, and implementing best practice methodologies and continuous improvement programs.`,
  },
  {
    avatar: lucaAvatar,
    name: 'Luca Riemma',
    position: 'Investor Relations',
    about: `Mr. Riemma is an international broker; heads the investor relations for TaTaTu, the third largest ICO to date raising 575m of private funding in 2018. \n 
    Mr. Riemma is a executive producer of film and television and has been working in the entertainment industry since 1997. He is a successful facilitator of several business dealings globally from food and beverage to technology and entertainment.`,
  },
];

let carouselVisibleSlides = 3;

function Team() {
  const [currentMember, setCurrentMember] = useState(-1);

  return (
    <div className='container3' id='team'>
      <div className='container3Upper'>
        <p className='theTeamTitle'>The Team</p>
        <p className='theTeamButtons'> &#10140; </p>
      </div>

      <Fade bottom duration='300'>
        <div className='carouselContainer'>
          <CarouselProvider
            naturalSlideWidth={400}
            naturalSlideHeight={500}
            visibleSlides={carouselVisibleSlides}
            totalSlides={6}
            isPlaying={true}
            interval={2000}
          >
            <Slider>
              {memberData.map((member, index) => (
                <Slide index={index}>
                  <div className='memberCard'>
                    <div className='avatarLogoContainer'>
                      <img
                        alt='Rachael Avatar'
                        src={member.avatar}
                        className='avatar'
                      />
                      <img
                        src={linkedInIcon}
                        alt={`${member.name}'s portrait`}
                        className='linkedInIcon'
                      />
                    </div>
                    <div className='memberInfoContainer'>
                      <p className='memberName'>{member.name}</p>
                      <p className='memberPosition'>{member.position}</p>
                      <p className='memberAbout'>{member.about}</p>
                      <img
                        alt='Dots button'
                        src={dotsIcon}
                        className='dotsButton'
                        onClick={() => setCurrentMember(index)}
                      />
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        </div>
      </Fade>

      {currentMember !== -1 && (
        <MemberModal
          members={memberData}
          currentMember={currentMember}
          setCurrentMember={setCurrentMember}
        />
      )}
    </div>
  );
}

export default Team;
